/**
 * Returns an appropriate connector string for a list of strings in a proper english narrative style.
 * Parameters are assumed to match array index perspectives, so idx is 0-based, and listLength is 1-based.
 * @param idx
 * @param listLength
 */
export function getListItemJunction(idx:number, listLength:number):string {
    if(idx < listLength - 2){
        return ', ';
    }
    else if(idx < listLength - 1){
        return ', and ';
    }
    return '';
}

const CURRENCY_REGEXP = new RegExp("^[$]([0-9]{1,3}(\\,[0-9]{3})*|([0-9]+))?(\\.[0-9]+)?$");
const PERCENT_REGEXP = new RegExp("^([0-9]{1,3}(\\,[0-9]{3})*|([0-9]+))?(\\.[0-9]+)?%$");
const NUMBER_PERCENT = new RegExp("^([0-9]{1,3}(\\,[0-9]{3})*|([0-9]+))?(\\.[0-9]+)?$");

export function parseCurrency(val: string): number | null {
    let result = null;
    const trimmedVal = val.trim();
    if (CURRENCY_REGEXP.test(trimmedVal)) {
        const parsed = Number.parseFloat(trimmedVal.substring(1).replace(/,/g, ""));
        if (!Number.isNaN(parsed)) {
            result = parsed;
        }
    }
    return result;
}

export function parsePercent(val: string): number | null {
    let result = null;
    const trimmedVal = val.trim();
    if (PERCENT_REGEXP.test(trimmedVal)) {
        const parsed = Number.parseFloat(trimmedVal.substring(0, trimmedVal.length - 1).replace(/,/g, ""));
        if (!Number.isNaN(parsed)) {
            result = parsed;
        }
    }
    return result;
}

export function parseNumber(val: string): number | null {
    let result = null;
    const trimmedVal = val.trim();
    if (NUMBER_PERCENT.test(trimmedVal)) {
        const parsed = Number.parseFloat(trimmedVal.replace(/,/g, ""));
        if (!Number.isNaN(parsed)) {
            result = parsed;
        }
    }
    return result;
}
