export const formatterDollarUSNoDecimal = Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    signDisplay: "negative"
});

export const formatterDollarUSNoDecimalSignAlways = Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    signDisplay: "always"
});

export const formatterDollarUSNoDecimalNoGrouping = Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    signDisplay: "negative",
    useGrouping: false
});

export const formatterDollarUS = Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
    signDisplay: "never",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});

export const formatterDollarUSNoSign = Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
    signDisplay: "never",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});

export const formatterInt = Intl.NumberFormat("en-us", {
    style: "decimal",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    signDisplay: "negative"
});

export const formatterIntNoGrouping = Intl.NumberFormat("en-us", {
    style: "decimal",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    signDisplay: "negative",
    useGrouping: false
});

export const formatterDecimal = Intl.NumberFormat("en-us", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});

export const formatterDecimalUnlimitedFractionDigits = Intl.NumberFormat("en-us", {
    style: "decimal",
});

export const formatterDecimalNoGrouping = Intl.NumberFormat("en-us", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    useGrouping: false
});

export const formatterPercent = Intl.NumberFormat("en-us", {
    style: "percent",
    maximumFractionDigits: 1,
    minimumFractionDigits: 1
});

export const formatterPercentNoGrouping = Intl.NumberFormat("en-us", {
    style: "percent",
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
    useGrouping: false
});

export const formatterPercentAutoSign = Intl.NumberFormat("en-us", {
    style: "percent",
    signDisplay: "auto",
    maximumFractionDigits: 1,
    minimumFractionDigits: 1
});

export const formatterPercentWithSign = Intl.NumberFormat("en-us", {
    style: "percent",
    signDisplay: "always",
    maximumFractionDigits: 1,
    minimumFractionDigits: 1
});

export const formatterDateUSFull = Intl.DateTimeFormat("en-us", {
    dateStyle: "full"
});

export const formatterDateTimeUS = (input: string | Date): string => {
    const date = typeof input === "string" ? new Date(input) : input;

    const options: Intl.DateTimeFormatOptions = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true
    };

    return date.toLocaleString("en-US", options);
};

export const formatterTimeUS = (input: string | Date): string => {
    const date = typeof input === "string" ? new Date(input) : input;

    const options: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        minute: "2-digit",
        hour12: true
    };

    return date.toLocaleString("en-US", options);
};

export function formatterEnumToTitle(enumValue: string): string {
    let specialsReplaced = enumValue;

    if (enumValue.includes("_")) {
        // if snake case
        specialsReplaced = specialsReplaced.replaceAll("_", " ").toLowerCase();
    } else if (enumValue.toUpperCase() != enumValue) {
        // if camel case
        specialsReplaced = specialsReplaced.replace(/([A-Z])/g, " $1").toLowerCase();
    } else {
        // just all caps, convert to title and move on
        return enumValue.charAt(0).toUpperCase() + enumValue.substring(1).toLowerCase();
    }

    return specialsReplaced.split(' ').map((x) => (
            x.charAt(0).toUpperCase() + x.substring(1))).join(' ');
}

export function getMonthUSFull(date: Date): string {
    const parts = formatterDateUSFull.formatToParts(date);
    let month = parts.find(part => part.type == "month")?.value;
    if (month == undefined) {
        month = `${date.getMonth() + 1}`;
    }

    return month;
}
