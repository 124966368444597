import {ReactElement, useEffect, useState} from "react";
import useAppStore from "../../../hooks/useAppStore";
import {ThemeProvider} from "styled-components";
import {ViziblyTheme} from "../../analyst/ViziblyZDGTheme";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import {AccountsTopCardTagsModel, TopCardTagType} from "../../../__generated__/generated_types";
import {buildChartOfAccountsFlatComponentsBottomLine, FinancialEntity, useChartOfAccounts} from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import useTopCardTagSettings from "./data/useTopCardTagSettings";
import TopCardTagRow from "./components/TopCardTagRow";
import {Spinner} from "@zendeskgarden/react-loaders";
import {COLORS} from "../../../constants/Colors";

import * as css from "./styles/topCardTagSettings.module.scss";
import * as adminCSS from "../styles/adminStyles.module.scss";
import AdminHeader from "../AdminHeader";

export default function TopCardTagSettings(): ReactElement {
    const appStore = useAppStore();
    const coa = useChartOfAccounts();
    const topCardTagSettings = useTopCardTagSettings();

    const [accounts, setAccounts] = useState<FinancialEntity[]>([]);
    const [groupedTagSelections, setGroupedTagSelections] = useState<Map<TopCardTagType, AccountsTopCardTagsModel[]>>();
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(
        () => {
            appStore.set({isLoading: false});
            topCardTagSettings.fetch([], []);
        },
        []
    );

    useEffect(
        () => {
            if (!coa.isReady || coa.chartOfAccounts == undefined || !coa.chartOfAccountsFlat) {
                return;
            }

            setAccounts(buildChartOfAccountsFlatComponentsBottomLine(coa.chartOfAccounts));
        },
        [coa.isReady]
    );

    useEffect(
        () => {
            if (!topCardTagSettings.data || topCardTagSettings.data == undefined || topCardTagSettings.loading || !topCardTagSettings.initialized) {
                return;
            }

            const groupedTags = new Map<TopCardTagType, AccountsTopCardTagsModel[]>(
                topCardTagSettings.data.map(x => (
                    [x.topCardTagType, topCardTagSettings.data.filter(y => y.topCardTagType == x.topCardTagType)]
                ))
            );

            setGroupedTagSelections(groupedTags);
        },
        [topCardTagSettings.data, topCardTagSettings.loading, topCardTagSettings.initialized]
    );

    useEffect(
        () => {
            if (!groupedTagSelections || groupedTagSelections == undefined) {
                return;
            }

            setInitialized(true);
        },
        [groupedTagSelections]
    );

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Grid className={adminCSS.adminWrapper}>
                <Row>
                    <Col>
                        <AdminHeader
                            title={"Financial Metrics Management"}
                            subtitle={"Map GL accounts to Vizibly's standard financial metrics."}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className={css.settingsTableWrapper}>
                            <div className={`${css.settingsTableRow} ${css.settingsTableRowHeader}`}>
                                <div className={css.settingsTableRowColumn}>Financial Metric</div>
                                <div className={css.settingsTableRowColumn}>GL Account</div>
                            </div>

                            {
                                initialized ?
                                    Object.values(TopCardTagType).map((type, index) => (
                                        <TopCardTagRow
                                            key={index}
                                            type={type}
                                            accounts={accounts}
                                            onChangeCallback={topCardTagSettings.set}
                                            selectedAccounts={groupedTagSelections?.get(type)?.map(x => x.accountId) ?? []}
                                        />
                                    ))
                                    : <div className={css.settingsTableLoader}>
                                        <Spinner size="54" color={COLORS.PRIMARY_500} />
                                    </div>
                            }
                        </div>
                    </Col>
                </Row>
            </Grid>
        </ThemeProvider>
    );
}
