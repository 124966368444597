import { IMenuTitleOption } from "../../components/menu-title/logic/menuTitle";
import { SERIES_DATA_OPTION } from "./logic/usePropertyExecutiveSummaryData";
import css from "./styles/css.module.scss";

export const CUSTOM_T12_TEXT = "T12";

export interface IClientBrandingAsset {
    name: string,
    colorPrimary: string,
    colorSecondary: string,
    basePath: string,
    stylingClass?: string,
}

export interface IClientBrandingAssetBuilt extends IClientBrandingAsset {
    banner: string | null,
    logo: string | null,
    logoMini: string | null,
}

export const OPERATIONAL_MENU_OPTIONS: IMenuTitleOption[] = [
    { label: 'Occupancy', value: SERIES_DATA_OPTION.OCCUPANCY },
    { label: 'Expirations', value: SERIES_DATA_OPTION.EXPIRATIONS },
    { label: 'Renewals', value: SERIES_DATA_OPTION.RENEWALS },
    { label: 'Renewal Ratios', value: SERIES_DATA_OPTION.RENEWAL_RATIOS },
    // { label: 'Renewal Trade Outs', value: SERIES_DATA_OPTION.RENEWAL_TRADEOUTS },
    { label: 'Move Outs', value: SERIES_DATA_OPTION.MOVE_OUTS },
    { label: 'Move Out Ratios', value: SERIES_DATA_OPTION.MOVE_OUTS_RATIOS },
    // { label: 'New Lease Trade Outs', value: SERIES_DATA_OPTION.NEW_LEASE_TRADEOUTS }
    // { label: 'Rents', value: SERIES_DATA_OPTION.MOVE_OUTS_RATIOS },
    { label: 'Net Operating Income', value: SERIES_DATA_OPTION.NOI },
    { label: 'Rental Income', value: SERIES_DATA_OPTION.RENTAL_INCOME },
    { label: 'Total Expenses', value: SERIES_DATA_OPTION.TOTAL_EXPENSE },
    { label: 'Budgeted Market Rents', value: SERIES_DATA_OPTION.BUDGETED_MARKET_RENT },
];

export const CLIENT_LOGO_ASSET_MAP: { [id: string]: IClientBrandingAsset } = {
    "6de1678f-59af-49b1-accd-4b76b612a160": {
        name: "assetLiving",
        colorPrimary: "#003C71",
        colorSecondary: "#00A1E4",
        basePath: "client_branding/asset_living",
        stylingClass: css.assetLiving,
    },
    "0b1f3821-0616-4fed-9ca6-bfa9ecfd8f3c": {
        name: "assetLiving",
        colorPrimary: "#003C71",
        colorSecondary: "#00A1E4",
        basePath: "client_branding/asset_living",
        stylingClass: css.assetLiving,
    },
    "bc45dc34-1f7e-11ee-be56-0242ac120002": {
        name: "bella",
        colorPrimary: "#11396c",
        colorSecondary: "#11396c",
        basePath: "client_branding/bella",
        stylingClass: css.bella,
    },
    "9d2e54b0-8ae7-42c0-b514-f749738bd617": {
        name: "ti",
        colorPrimary: "#0a456e",
        colorSecondary: "#0a456e",
        basePath: "client_branding/ti",
        stylingClass: css.ti,
    },
    "51bd460e-19cf-11ee-be56-0242ac120002": {
        name: "haven",
        colorPrimary: "#0f426b",
        colorSecondary: "#0f426b",
        basePath: "client_branding/haven",
        stylingClass: css.haven,
    },
};

export function loadClientBrandAsset(id?: string): IClientBrandingAssetBuilt {
    const defaultAsset: IClientBrandingAssetBuilt = {
        name: "default",
        colorPrimary: "#083D41",
        colorSecondary: "#888888",
        basePath: "executive_summary",
        banner: "executive_summary/bg_img.jpg",
        logo: null,
        logoMini: null,
    };

    const clientAsset = CLIENT_LOGO_ASSET_MAP[id || ""];

    if (clientAsset) {
        return {
            ...clientAsset,
            banner: `${clientAsset.basePath}/bg_img.jpg`,
            logo: `${clientAsset.basePath}/logo.png`,
            logoMini: `${clientAsset.basePath}/logo_mini.png`,
        };
    }

    return defaultAsset;
}
