import { ReactElement, useEffect, useRef, useState } from "react";
import { TDriverGrowthMetric } from "../../../../../../contexts/account/data/logic/driversAndWorksheetData";

import * as css from "./styles/css.module.scss";
import { deleteNodeKeys, TFormulaNodeProps } from "./logic/formulaNode";
import { DistributionMethod, PercentGrowthBasePeriod, VersionType } from "../../../../../../__generated__/generated_types";
import PercentGrowthMenu from "../formula-menu/PercentGrowthMenu";
import { distributionMethodMap } from "../../../../../../contexts/account/data/utils";
import { useProperties } from "../../../../../../contexts/properties/PropertiesContext";
import { getBaseToGrowOptions } from "../../logic/utils";


type TGrowthDriverFxNodeProps = TFormulaNodeProps&{
    drivers: TDriverGrowthMetric[],
    versionType: VersionType.Budget | VersionType.Reforecast,
}

export default function PercentGrowthDriverFxNode(props: TGrowthDriverFxNodeProps): ReactElement {
    const { currentProperty } = useProperties();

    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [childNodeHovered, setChildNodeHovered] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                (wrapperRef.current === event.target ||
                wrapperRef.current.contains(event.target as Node))
            ) {
                return;
            }
            setIsFocused(false);
        };

        // Clicking outside the current node should remove focus
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClick = () => {
        setIsFocused(!isFocused);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            isFocused && deleteNodeKeys.includes(e.key)
        ) {
            props.fbUpdates.removePercentGrowthDriver();
        }
    };

    if (!currentProperty) {
        return <></>;
    }

    let percentGrowthBasePeriod: string = props.drivers[0]?.percentGrowthBasePeriod || PercentGrowthBasePeriod.SameMonthLastYear || PercentGrowthBasePeriod.BudgetSameMonthCurrentYear;
    percentGrowthBasePeriod = getBaseToGrowOptions(props.versionType, currentProperty.reforecastYear).find((e) => e.value === percentGrowthBasePeriod)?.label || '';

    const percentGrowthAdjustmentValue = props.drivers[0]?.percentGrowthAdjustmentValue || 0;

    const distributionMethod = props.drivers[0]?.distributionMethod || DistributionMethod.Flat;

    const basePeriodNode = <a style={{marginLeft: "6px"}}>{percentGrowthBasePeriod}</a>;
    const adjustmentValueNode = <a style={{marginLeft: "6px"}}>{percentGrowthAdjustmentValue}% growth</a>;
    const distributionMethodNode = <a style={{marginLeft: "6px"}}>{distributionMethodMap[distributionMethod]}</a>;

    const nodeClassName = props.locked ? css.fxNodeLocked : css.fxNode;
    const className = `${nodeClassName} ${props.className ? props.className : ''} ${css.deletableNode} ${isFocused ? css.focusedNode : ''} ${childNodeHovered ? css.disableHover : ''}`;

    // TODO probably need to refactor this to have a more direct comparison of selected driver to corresponding enum value
    const showDistributionNode = (percentGrowthBasePeriod.toLowerCase() !== PercentGrowthBasePeriod.SameMonthLastYear.replaceAll("_", " ").toLowerCase()
                                 && percentGrowthBasePeriod !== `Same month ${currentProperty.reforecastYear} budget amount`);

    const renderBasePeriod = (): string | JSX.Element => {
        if (props.locked) {
            return <>&nbsp;{percentGrowthBasePeriod}</>;
        }
        return (
            <PercentGrowthMenu
                fbUpdates={props.fbUpdates}
                level={0}
                triggerNode={basePeriodNode}
                versionType={props.versionType}
            />
        );
    };

    const renderAdjustmentValueNode = (): string | JSX.Element => {
        if (props.locked) {
            return <>&nbsp;{percentGrowthAdjustmentValue}% growth</>;
        }
        return (
            <PercentGrowthMenu
                fbUpdates={props.fbUpdates}
                level={1}
                triggerNode={adjustmentValueNode}
                versionType={props.versionType}
            />
        );
    };

    const renderDistributionMethodNode = (): string | JSX.Element => {
        if (props.locked) {
            return <>&nbsp;{distributionMethodMap[distributionMethod]}</>;
        }
        return (
            <PercentGrowthMenu
                fbUpdates={props.fbUpdates}
                level={2}
                triggerNode={distributionMethodNode}
                versionType={props.versionType}
            />
        );
    };

    return (
        <div
            className={className}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onMouseEnter={() => setChildNodeHovered(false)}
            ref={wrapperRef}
            tabIndex={0}
        >
            (% Growth:
            <div
                className={css.fxNode}
                onMouseEnter={() => setChildNodeHovered(true)}
                onMouseLeave={() => setChildNodeHovered(false)}
            >
                {renderBasePeriod()}
            </div>
            &nbsp;+
            <div
                className={css.fxNode}
                onMouseEnter={() => setChildNodeHovered(true)}
                onMouseLeave={() => setChildNodeHovered(false)}
            >
                {renderAdjustmentValueNode()}
            </div>
            {showDistributionNode && (
                <>
                    &nbsp;with
                    <div
                        className={css.fxNode}
                        onMouseEnter={() => setChildNodeHovered(true)}
                        onMouseLeave={() => setChildNodeHovered(false)}
                    >
                        {renderDistributionMethodNode()}
                    </div>
                </>
            )}
            )
        </div>
    );
}
