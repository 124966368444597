
import { buildCellsCsv, buildHeaderCellsCsv, buildNumbers, buildPropertyNumbersSum, buildPropertyNumbersWeightedAverage, buildRatio } from "../../workflows/operational/summary/content_builders";
import { formatterIntNoGrouping, formatterPercentNoGrouping, formatterDecimalNoGrouping, formatterDollarUSNoDecimal } from "../../../utils/formatters";
import { Property } from "../../../contexts/properties/PropertiesContext";
import { GetRevenueSummaryUnitTypeQuery } from "../../../__generated__/generated_types";
import { UnitTypeFieldKey } from "../../workflows/operational/summary/RevenueSummaryTable";
import { MONTHS } from "../../../constants/Months";
import { chunkArray } from "./pdfExportHelpers";


export function revenueTableDataToRows(property: Property, unitTypeData: GetRevenueSummaryUnitTypeQuery["originalRevenueModel"]): string[][][] {
    if (!property || !unitTypeData) {
        return [];
    }
    const builtRows = [
        buildHeaderCellsCsv(property.reforecastStartMonthIndex, property.budgetYear).map(a => a.replaceAll("-", `${'\n'}`))
    ];

    function tableBlockRowExpandableCsv({
        label,
        unitTypeField,
        formatter,
        propertyAggregate = "sum",
        unitTypeFieldWeights
    }:
    {
    label: string,
    unitTypeField: UnitTypeFieldKey,
    formatter: Intl.NumberFormat,
    propertyAggregate?: "sum" | "wavg" | "ratio",
    unitTypeFieldWeights?: UnitTypeFieldKey
    }
    ): string[][] {
    const revenueData = unitTypeData;
    if (!property || !revenueData) {
        return [];
    }

    let propertyNumbers: number[] = [];
    if (propertyAggregate == "sum") {
        propertyNumbers = buildPropertyNumbersSum(
                property.reforecastStartMonthIndex,
                revenueData[unitTypeField],
                "all");
    }
    else if (propertyAggregate == "wavg" && unitTypeFieldWeights) {
        propertyNumbers = buildPropertyNumbersWeightedAverage(
                property.reforecastStartMonthIndex,
                revenueData[unitTypeField],
                revenueData[unitTypeFieldWeights],
                "all");
    }
    else if (propertyAggregate == "ratio" && unitTypeFieldWeights) {
        const numeratorPropertyNumbers = buildPropertyNumbersSum(
                property.reforecastStartMonthIndex,
                revenueData[unitTypeField],
                "all");
        const denominatorPropertyNumbers = buildPropertyNumbersSum(
                property.reforecastStartMonthIndex,
                revenueData[unitTypeFieldWeights],
                "all");
        propertyNumbers = buildRatio(numeratorPropertyNumbers, denominatorPropertyNumbers);
    }
    else {
        return [];
    }
    return (
        [
            [
                label,
                ...buildCellsCsv(
                    property.reforecastStartMonthIndex,
                    propertyNumbers,
                    formatter
                )
            ],
            ...revenueData[unitTypeField]
                .sortBy("unitTypeName").map(utData =>
                    [
                        ` ${utData.unitTypeName}`,
                        ...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, utData), formatter)
                    ]
                )
        ]
    );
    }

    function tableBlockRowRatioCsv({
        label,
        unitTypeNumeratorField,
        formatter,
        unitTypeDenominatorField
    }: {
        label: string,
        unitTypeNumeratorField: UnitTypeFieldKey,
        formatter: Intl.NumberFormat,
        unitTypeDenominatorField: UnitTypeFieldKey
    }): string[][] {
        const revenueData = unitTypeData;
        if (!property || !revenueData) {
            return [];
        }

        let propertyNumbers: number[] = [];
        const numeratorPropertyNumbers = buildPropertyNumbersSum(
                property.reforecastStartMonthIndex,
                revenueData[unitTypeNumeratorField],
                "all");
        const denominatorPropertyNumbers = buildPropertyNumbersSum(
                property.reforecastStartMonthIndex,
                revenueData[unitTypeDenominatorField],
                "all");
        propertyNumbers = buildRatio(numeratorPropertyNumbers, denominatorPropertyNumbers);
        return (
                [
                    [label,
                        ...buildCellsCsv(
                            property.reforecastStartMonthIndex,
                            propertyNumbers,
                            formatter
                        )
                    ]
                ]
        );
    }


    builtRows.push(["METRICS"]);
    builtRows.push(...tableBlockRowExpandableCsv({label: "Unit Count", unitTypeField: "unitTypeUnitCountMonthly", formatter: formatterIntNoGrouping}));
    builtRows.push(...tableBlockRowExpandableCsv({label: "Expiration Count", unitTypeField: "unitTypeExpirationCount", formatter: formatterIntNoGrouping}));
    builtRows.push(...tableBlockRowRatioCsv({label: "Occupied %", unitTypeNumeratorField: "unitTypeOccupiedCount", unitTypeDenominatorField: "unitTypeUnitCountMonthly", formatter: formatterPercentNoGrouping}));
    builtRows.push(...tableBlockRowExpandableCsv({label: "Occupied Count", unitTypeField: "unitTypeOccupiedCount", formatter: formatterIntNoGrouping}));
    builtRows.push(...tableBlockRowRatioCsv({label: "Renewal %", unitTypeNumeratorField: "unitTypeRenewalCount", unitTypeDenominatorField: "unitTypeExpirationCount", formatter: formatterPercentNoGrouping}));
    builtRows.push(...tableBlockRowExpandableCsv({label: "Renewal Count", unitTypeField: "unitTypeRenewalCount", formatter: formatterIntNoGrouping}));
    builtRows.push(...tableBlockRowExpandableCsv({label: "Move In Count", unitTypeField: "unitTypeMoveInCount", formatter: formatterDecimalNoGrouping}));
    builtRows.push(["Total Move In Count", ...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers( property.reforecastStartMonthIndex, unitTypeData.totalMoveInCount), formatterDecimalNoGrouping)]);
    builtRows.push(...tableBlockRowRatioCsv({label: "Move Out %", unitTypeNumeratorField: "unitTypeMoveOutCount", unitTypeDenominatorField: "unitTypeExpirationCount", formatter: formatterPercentNoGrouping}));
    builtRows.push(...tableBlockRowExpandableCsv({label: "Move Out Count", unitTypeField: "unitTypeMoveOutCount", formatter: formatterDecimalNoGrouping}));
    builtRows.push(["Early Term Count", ...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers( property.reforecastStartMonthIndex, unitTypeData.earlyTerminationCount), formatterIntNoGrouping)]);
    builtRows.push(["MTM Move Out Count",...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.m2mMoveOutCount),formatterIntNoGrouping)]);
    builtRows.push(["Total Move Out Count", ...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers( property.reforecastStartMonthIndex, unitTypeData.totalMoveOutCount), formatterDecimalNoGrouping)]);
    builtRows.push(["AMOUNTS"]);
    builtRows.push(...tableBlockRowExpandableCsv({label: "Avg Market Rent", unitTypeField: "unitTypeAvgMarketRent", formatter: formatterDollarUSNoDecimal, propertyAggregate: "wavg", unitTypeFieldWeights: "unitTypeUnitCountMonthly"}));
    builtRows.push(...tableBlockRowExpandableCsv({label: "In Place Rent", unitTypeField: "unitTypeInPlaceRent", formatter: formatterDollarUSNoDecimal, propertyAggregate: "wavg", unitTypeFieldWeights: "unitTypeUnitCountMonthly"}));
    builtRows.push(...tableBlockRowExpandableCsv({label: "New Lease Tradeout", unitTypeField: "unitTypeNewLeaseTradeOut", formatter: formatterDollarUSNoDecimal, propertyAggregate: "wavg", unitTypeFieldWeights: "unitTypeMoveInCount"}));
    builtRows.push(...tableBlockRowExpandableCsv({label: "Renewal Tradeout", unitTypeField: "unitTypeRenewalTradeOut", formatter: formatterDollarUSNoDecimal, propertyAggregate: "wavg", unitTypeFieldWeights: "unitTypeRenewalCount"}));
    builtRows.push(["FINANCIALS"]);
    builtRows.push(["Market Rent",...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.rent),formatterDollarUSNoDecimal)]);
    builtRows.push(["Loss To Lease",...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.lossToLease),formatterDollarUSNoDecimal)]);
    builtRows.push(["Gross Potential Rent",...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.grossPotentialRent),formatterDollarUSNoDecimal)]);
    builtRows.push(["GPR Change",...buildCellsCsv(property.reforecastStartMonthIndex, buildNumbers(property.reforecastStartMonthIndex, unitTypeData.momLossToLeaseChange),formatterDollarUSNoDecimal)]);

    let colHeaders = builtRows.shift();

    if (!colHeaders) {
        return [];
    }

    const finalCols: string[] = ['Description', ...MONTHS];
    const bdgtValues = builtRows.map(x => {
        const unitLabel = x[0];
        const monthValues = x.slice(1).slice(-12);

        if (unitLabel && monthValues) {
            return [unitLabel, ...monthValues];
        } else {
            return []
        }
    });

    const chunkedData = chunkArray(bdgtValues, 28).map(chunk => [finalCols, ...chunk]);

    return chunkedData;
}
