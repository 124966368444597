import { ReactElement, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import {ViziblyTheme} from "../styles/zendesk-garden/ViziblyZDGTheme";
import useAppStore from "../hooks/useAppStore";
import FlappyViz from "./flappy-viz/FlappyViz";

export function FunPlayground(): ReactElement {
    const appStore = useAppStore();

    useEffect(() => {
        appStore.set({ isLoading: false });
    }, []);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div style={{marginTop: "75px", padding: "2rem"}}>

                <div>
                    <h4 style={{borderBottom: "1px solid #aaaaaa", paddingBottom: ".5rem", marginBottom: "1rem"}}>FlappyViz</h4>
                    <FlappyViz />
                </div>

            </div>
        </ThemeProvider>
    );
}
