import { PropertyExecutiveSummaryData } from "../logic/usePropertyExecutiveSummaryData";
import { formatterDollarUSNoDecimal, formatterPercentAutoSign, formatterPercentWithSign } from '../../../utils/formatters';
import { IMetricBlock } from './pdfExportHelpers';
import { buildRentsStatementMarketRent, buildRentsStatementMoveOutRatio, buildRentsStatementOccupancy, buildRentsStatementRenewalRatio, buildRentsStatementResidentsTurnover, buildRentsStatementTradeouts } from "../logic/summaryStringBuilders";

export function buildExecSummaryMetricData(data: PropertyExecutiveSummaryData): Record<string, IMetricBlock> {
    const obj: Record<string, IMetricBlock> = {
        occupancy: {
            title: "occupancy",
            isSmallTitle: true,
            values: [
                {
                    value: data && data.occupancyStat.budgetAvgOccupancy !== null ? formatterPercentAutoSign.format(data.occupancyStat.budgetAvgOccupancy) : "",
                    bottomLabel: data && data.occupancyStat.budgetAvgOccupancy !== null
                                  && data.occupancyStat.reforecastDecember !== null
                                  ?
                                  `${formatterPercentWithSign.format(data.occupancyStat.budgetAvgOccupancy - data.occupancyStat.reforecastDecember)} vs '${data.property.reforecastYear % 100} Reforecast`
                                  : "",
                }
            ],
            text: buildRentsStatementOccupancy(data),
        },
        resTurn: {
            title: "resident turnover",
            isSmallTitle: true,
            values: [
                {
                    topLabel: "Renewal Ratio",
                    value: buildRentsStatementRenewalRatio(data),
                },
                {
                    topLabel: "Move Out Ratio",
                    value: buildRentsStatementMoveOutRatio(data),
                }
            ],
            text: buildRentsStatementResidentsTurnover(data),
        },
        tradeouts: {
            title: "tradeouts",
            isSmallTitle: true,
            values: [
                {
                    topLabel: "New Lease Tradeout",
                    value: data && data.newLeaseTradeOutPercent !== null ?
                            formatterPercentWithSign.format(data.newLeaseTradeOutPercent)
                            :
                            "",
                },
                {
                    topLabel: "Renewal Tradeout",
                    value: data && data.renewalTradeOutPercent !== null ?
                            formatterPercentWithSign.format(data.renewalTradeOutPercent)
                            :
                            "",
                }
            ],
            text: buildRentsStatementTradeouts(data),
        }
    };

    if(data?.budgetedAvgMarketRent.budgetedAvgBudgetMarketRent) {
        obj["mktRent"] = {
            title: "avg budgeted market rents",
            isSmallTitle: true,
            values: [
                {
                    value: data && data.budgetedAvgMarketRent.budgetedAvgBudgetMarketRent !== null ? formatterDollarUSNoDecimal.format(data.budgetedAvgMarketRent.budgetedAvgBudgetMarketRent) : "",
                    bottomLabel: `${data && data.budgetedAvgMarketRent.variancePercent !== null ? formatterPercentWithSign.format(data.budgetedAvgMarketRent.variancePercent) : ""} YOY`,
                }
            ],
            text: buildRentsStatementMarketRent(data),
        };
    }

    return obj;
}