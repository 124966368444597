import { ReactElement, useEffect, useRef, useState } from "react";
import css from "./css.module.scss";
import { Button } from "@zendeskgarden/react-buttons";
import flapChar from "../../assets/icons/brand/flap_char.png";
import flappyTitle from "../../assets/images/fun/flappy_viz_title.png";
import mountainBG from "../../assets/images/fun/mountains.png";
import cloudBG1 from "../../assets/images/fun/cloudLayerB1.png";
import cloudBG2 from "../../assets/images/fun/cloudLayer2.png";
import { COLORS } from "../../constants/Colors";
import cn from "classnames";
import { animateBackgroundLayer } from "./utils";
import { Field, Label, Radio } from "@zendeskgarden/react-forms";
import { toast } from "react-toastify";

export default function FlappyViz(): ReactElement {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const requestRef = useRef<number>(0);
    const birdYRef = useRef<number>(200);
    const birdVelocityRef = useRef<number>(0);
    const gravity = 0.05;
    const flapStrength = -2.7;
    const pipes = useRef<{ x: number; height: number }[]>([]);
    const pipeGap = 170;
    const pipeWidth = 60;
    const pipeSpeed = 2;
    const pipeSpacing = 350;
    const [gameStarted, setGameStarted] = useState<boolean>(false);
    const [playerScore, setPlayerScore] = useState<number>(0);
    const [difficulty, setDifficulty] = useState<number>(.8);
    const [highScore, setHighScore] = useState<number>(0);

    const birdImageRef = useRef<HTMLImageElement | null>(null);

    const mountainBGImageRef = useRef<HTMLImageElement | null>(null);
    const mountainBGXPosRef = useRef<number>(0);

    const cloudBG1ImageRef = useRef<HTMLImageElement | null>(null);
    const cloudBG1XPosRef = useRef<number>(0);

    const cloudBG2ImageRef = useRef<HTMLImageElement | null>(null);
    const cloudBG2XPosRef = useRef<number>(0);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext("2d");

        const birdImage = new Image();
        birdImage.onload = () => { birdImageRef.current = birdImage; };
        birdImage.src = flapChar;

        const mountainBGImage = new Image();
        mountainBGImage.onload = () => { mountainBGImageRef.current = mountainBGImage; };
        mountainBGImage.src = mountainBG;

        const cloudBG1Image = new Image();
        cloudBG1Image.onload = () => { cloudBG1ImageRef.current = cloudBG1Image; };
        cloudBG1Image.src = cloudBG1;

        const cloudBG2Image = new Image();
        cloudBG2Image.onload = () => { cloudBG2ImageRef.current = cloudBG2Image; };
        cloudBG2Image.src = cloudBG2;

        if (!canvas || !context) return;

        canvas.width = 700;
        canvas.height = 500;

        // Initialize pipes
        pipes.current = Array.from({ length: Math.ceil(canvas.width / pipeSpacing) + 3 }, (_, i) => ({
            x: canvas.width + i * pipeSpacing,
            height: Math.floor(Math.random() * (canvas.height - pipeGap)),
        }));

        function flap() {
            birdVelocityRef.current = flapStrength;
        }

        const handleMouseClick = (e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();

            if (gameStarted) {
                flap();
            }
        };

        flap();

        window.addEventListener("mousedown", handleMouseClick);

        const gameLoop = () => {
            if (!context || !birdImageRef.current || !mountainBGImageRef.current || !cloudBG1ImageRef.current) return;

            const diffAdjustedPipeSpeed = pipeSpeed * difficulty;

            // Clear canvas
            context.clearRect(0, 0, canvas.width, canvas.height);

            // Draw first cloud layer background
            animateBackgroundLayer({
                imageHeightFactor: .5,
                imageXPos: cloudBG2XPosRef,
                imageYPos: .1,
                imageRef: cloudBG2ImageRef,
                canvasContext: context,
                canvasWidth: canvas.width,
                canvasHeight: canvas.height,
                scrollSpeed: .3,
            });

            // Draw second cloud layer background
            animateBackgroundLayer({
                imageHeightFactor: .5,
                imageXPos: cloudBG1XPosRef,
                imageYPos: .3,
                imageRef: cloudBG1ImageRef,
                canvasContext: context,
                canvasWidth: canvas.width,
                canvasHeight: canvas.height,
                scrollSpeed: .4,
            });

            // Draw mountain background
            animateBackgroundLayer({
                imageHeightFactor: .6,
                imageXPos: mountainBGXPosRef,
                imageYPos: .4,
                imageRef: mountainBGImageRef,
                canvasContext: context,
                canvasWidth: canvas.width,
                canvasHeight: canvas.height,
                scrollSpeed: .7,
            });

            // Draw bird
            birdVelocityRef.current += gravity;
            birdYRef.current += birdVelocityRef.current;
            context.drawImage(birdImageRef.current, 50, birdYRef.current, 40, 40);

            // Draw pipes and manage movement
            pipes.current.forEach((pipe, index) => {
                pipe.x -= diffAdjustedPipeSpeed;
                if (pipe.x + pipeWidth < 0) {
                    // Recycle pipe to the right of the screen
                    pipes.current[index] = {
                        x: Math.max(...pipes.current.map(p => p.x)) + pipeSpacing,
                        height: Math.floor(Math.random() * (canvas.height - pipeGap)),
                    };
                }
                context.fillStyle = COLORS.GREEN_500;
                context.fillRect(pipe.x, 0, pipeWidth, pipe.height);
                context.fillRect(pipe.x, pipe.height + pipeGap, pipeWidth, canvas.height);

                // Check for collision
                if (
                    50 < pipe.x + pipeWidth &&
                    70 > pipe.x &&
                    (birdYRef.current < pipe.height || birdYRef.current + 40 > pipe.height + pipeGap)
                ) {
                    // Collision detected, reset game
                    birdYRef.current = 200;
                    birdVelocityRef.current = 0;
                    pipes.current = Array.from({ length: Math.ceil(canvas.width / pipeSpacing) + 3 }, (_, i) => ({
                        x: canvas.width + i * pipeSpacing,
                        height: Math.floor(Math.random() * (canvas.height - pipeGap)),
                    }));
                    setGameStarted(false);
                }

                // Check if bird successfully passed through a pipe
                if (pipe.x + pipeWidth < 50 && pipe.x + pipeWidth + diffAdjustedPipeSpeed >= 50) {
                    setPlayerScore(prevScore => prevScore + 1);
                }
            });

            // Check if bird hits the ground or goes too high
            if (birdYRef.current > canvas.height || birdYRef.current < 0) {
                birdYRef.current = 200;
                birdVelocityRef.current = 0;
                pipes.current = Array.from({ length: Math.ceil(canvas.width / pipeSpacing) + 3 }, (_, i) => ({
                    x: canvas.width + i * pipeSpacing,
                    height: Math.floor(Math.random() * (canvas.height - pipeGap)),
                }));
                setGameStarted(false);
            }

            requestRef.current = requestAnimationFrame(gameLoop);
        };

        if (gameStarted) {
            requestRef.current = requestAnimationFrame(gameLoop);
        } else {
            if (playerScore > highScore) {
                setHighScore(playerScore);
                toast("New high score!", {type: "success"});
            }
            setPlayerScore(0);
        }

        return () => {
            window.removeEventListener("mousedown", handleMouseClick);
            cancelAnimationFrame(requestRef.current);
        };
    }, [gameStarted]);

    return (
        <div className={cn(css.flapGameWrapper, gameStarted && css.gameStarted)}>
            <div className={cn(css.uiOverlayStartScreen)}>
                <img src={flappyTitle} className={css.title} />
                <h5 className={css.instructions}>Click to "flap", and try to avoid hitting the green bars!</h5>
                <div className={css.difficultySelections}>
                    <Field>
                        <Radio
                            name="easy"
                            value={.8}
                            checked={difficulty === .8}
                            onChange={event => setDifficulty(parseFloat(event.target.value))}
                        >
                            <Label>Easy</Label>
                        </Radio>
                    </Field>

                    <Field>
                        <Radio
                            name="medium"
                            value={1.1}
                            checked={difficulty === 1.1}
                            onChange={event => setDifficulty(parseFloat(event.target.value))}
                        >
                            <Label>Medium</Label>
                        </Radio>
                    </Field>

                    <Field>
                        <Radio
                            name="hard"
                            value={1.6}
                            checked={difficulty === 1.6}
                            onChange={event => setDifficulty(parseFloat(event.target.value))}
                        >
                            <Label>Hard</Label>
                        </Radio>
                    </Field>
                </div>

                <Button
                    className={css.bounce}
                    isPrimary
                    onClick={() => setGameStarted(true)}
                >
                    Click to Start
                </Button>

                <img src={mountainBG} className={css.startBackground} />
                <h4 className={css.highScore}>High Score: {highScore == 0 ? "-" : highScore}</h4>
            </div>

            <h4 className={cn(css.playerScore)}>Score: {playerScore}</h4>
            <canvas ref={canvasRef} className={cn(css.gameCanvas)} />
        </div>
    );
}
