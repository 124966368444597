import React, { ReactElement } from "react";
import { MONTHS } from "../../../../constants/Months";
import { Cell, HeaderCell } from "@zendeskgarden/react-tables";
import * as css from "./revenueSummary.module.scss";
import { OriginalRevenueForecastedNumbersModel, OriginalRevenueUnitTypeNumbersModel } from "../../../../__generated__/generated_types";
import cn from "classnames";
import tableCSS from "../../../property-executive-summary/styles/table.module.scss";

export function buildLastActualMonthIndex(reforecastStartMonthIndex: number): number {
    let lastActualMonthIndex = reforecastStartMonthIndex - 1;
    if (lastActualMonthIndex < 0) {
        lastActualMonthIndex = 11;
    }

    return lastActualMonthIndex;
}

export function buildHeaderCellsCsv(reforecastStartMonthIndex: number, budgetYear: number): string[] {
    let actualLabelYear = budgetYear - 1;
    const lastActualMonthIndex = buildLastActualMonthIndex(reforecastStartMonthIndex);
    if (reforecastStartMonthIndex == 0) {
        actualLabelYear = budgetYear - 2;
    }
    return (
        [
            "",
            `ACTUAL-${actualLabelYear.toString().slice(2)}-${MONTHS[lastActualMonthIndex]}`,
            ...new Array(12 - reforecastStartMonthIndex).fill(null).map((_, index) => (
                `RFCST${index == 0 ? "-" + (budgetYear - 1).toString().slice(2) : ""}-${MONTHS[index + reforecastStartMonthIndex]}`
            )),
            ...new Array(12).fill(null).map((_, index) => (
                `BDGT${index == 0 ? "-" + budgetYear.toString().slice(2) : ""}-${MONTHS[index]}`
            ))
        ]
    );
}

export function buildHeaderCells(reforecastStartMonthIndex: number, budgetYear: number, hideReforecast = false): ReactElement[] {
    let actualLabelYear = budgetYear - 1;
    let reforecastLabelYear: number|undefined = undefined;
    const lastActualMonthIndex = buildLastActualMonthIndex(reforecastStartMonthIndex);
    if (reforecastStartMonthIndex == 0) {
        actualLabelYear = budgetYear - 2;
        reforecastLabelYear = budgetYear - 1;
    }

    let actualAndReforecastHeaders: ReactElement[] = [];
    if(!hideReforecast) {
        actualAndReforecastHeaders = new Array(12 - reforecastStartMonthIndex).fill(null).map((_, index) => (
                <HeaderCell key={`rfcst-${index}`}>
                    <div className={css.headerCellContent}>
                        <span>RFCST</span>
                        <span>{reforecastLabelYear !== undefined && index == 0 ? reforecastLabelYear.toString().slice(2) : ""}</span>
                        <span>{MONTHS[index + reforecastStartMonthIndex]}</span>
                    </div>
                </HeaderCell>
        ));
        actualAndReforecastHeaders.unshift(
                <HeaderCell>
                    <div className={cn(css.headerCellContent)}>
                        <span>ACTUAL</span>
                        <span>{actualLabelYear.toString().slice(2)}</span>
                        <span>{MONTHS[lastActualMonthIndex]}</span>
                    </div>
                </HeaderCell>
        );
    }

    return (
        [
            <HeaderCell className={cn(tableCSS.fixedCol, tableCSS.firstCol, tableCSS.topHeader)}>Description</HeaderCell>,
            ...actualAndReforecastHeaders,
            ...new Array(12).fill(null).map((_, index) => (
                <HeaderCell key={`bdgt-${index}`}>
                    <div className={css.headerCellContent}>
                        <span>BDGT</span>
                        <span>{index == 0 ? budgetYear.toString().slice(2) : ""}</span>
                        <span>{MONTHS[index]}</span>
                    </div>
                </HeaderCell>
            ))
        ]
    );
}

export function buildPropertyNumbersSum(
    reforecastStartMonthIndex: number,
    rawNumbersIn: Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>[],
    selectedUnitType: string
): number[] {
    const ret = new Array(36).fill(0);
    let rawNumbers = rawNumbersIn;
    if (selectedUnitType !== "all") {
        rawNumbers = rawNumbersIn.filter(({ unitTypeId }) => unitTypeId == selectedUnitType);
    }
    for (const row of rawNumbers) {
        for (let i = 0; i < 36; i++) {
            if (i < 12) {
                ret[i] += row.actuals[i];
            }
            else if (i >= 12 && i < 24) {
                ret[i] += row.reforecast[i-12];
            }
            else {
                ret[i] += row.budget[i-24];
            }
        }
    }
    const lastActualMonthIndex = buildLastActualMonthIndex(reforecastStartMonthIndex);
    return ret.slice(0, lastActualMonthIndex + 1).concat(ret.slice(12 + reforecastStartMonthIndex));
}

export function buildPropertyNumbersWeightedAverage(
    reforecastStartMonthIndex: number,
    rawNumbersIn: Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>[],
    rawWeightsIn: Pick<OriginalRevenueUnitTypeNumbersModel, 'unitTypeId' | 'unitTypeName' | 'actuals' | 'reforecast' | 'budget'>[],
    selectedUnitType: string
): number[] {
    let rawNumbers = rawNumbersIn;
    if (selectedUnitType !== "all") {
        rawNumbers = rawNumbersIn.filter(({ unitTypeId }) => unitTypeId == selectedUnitType);
    }
    let rawWeights = rawWeightsIn;
    if (selectedUnitType !== "all") {
        rawWeights = rawWeightsIn.filter(({ unitTypeId }) => unitTypeId == selectedUnitType);
    }
    const ret = new Array(36).fill(0);
    const rawWeightsById = rawWeights.toIdMap("unitTypeId");
    const weightsSum = new Array(36).fill(0);
    for (const numbersRow of rawNumbers) {
        const weightsRow = rawWeightsById[numbersRow.unitTypeId];
        if (!weightsRow) {
            continue;
        }
        for (let i = 0; i < 36; i++) {

            let num = null;
            let weight = null; weightsRow.actuals[i];
            if (i < 12) {
                num = numbersRow.actuals[i];
                weight = weightsRow.actuals[i];
            }
            else if (i >= 12 && i < 24) {
                num = numbersRow.reforecast[i - 12];
                weight = weightsRow.reforecast[i - 12];
            }
            else {
                num = numbersRow.budget[i - 24];
                weight = weightsRow.budget[i - 24];

            }
            if (num && weight) {
                ret[i] += num * weight;
                weightsSum[i] += weight;
            }
        }
    }
    for (let i = 0; i < 36; i++) {
        if (Math.abs(weightsSum[i]) > 0.0001) {
            ret[i] /= weightsSum[i];
        }
        else {
            ret[i] = 0;
        }
    }

    const lastActualMonthIndex = buildLastActualMonthIndex(reforecastStartMonthIndex);
    return ret.slice(0, lastActualMonthIndex + 1).concat(ret.slice(12 + reforecastStartMonthIndex));
}

export function buildNumbers(reforecastStartMonthIndex: number, rawNumbers: Pick<OriginalRevenueForecastedNumbersModel, "actuals" | "reforecast" | "budget">): (number | null)[] {
    const lastActualMonthIndex = buildLastActualMonthIndex(reforecastStartMonthIndex);
    const ret = rawNumbers.actuals.slice(0, lastActualMonthIndex + 1).concat(rawNumbers.reforecast.slice(reforecastStartMonthIndex)).concat(rawNumbers.budget);
    return ret;
}

export function buildRatio(numerator: number [], denominator: number[]): number[] {
    const len = Math.min(numerator.length, denominator.length);
    const ret = new Array(Math.max(numerator.length, denominator.length)).fill(0);

    for (let i = 0; i < len; i++) {
        const d = denominator[i];
        const n = numerator[i];
        if (d && Math.abs(d) > 0.0001 && n) {
            ret[i] = n/d;
        }
    }

    return ret;
}

export function buildCells(reforecastStartMonthIndex: number, numbers: (number | null)[], formatter: Intl.NumberFormat, hideReforecast = false): ReactElement[] {
    let lastActualMonthIndex = buildLastActualMonthIndex(reforecastStartMonthIndex);
    if(hideReforecast) {
        lastActualMonthIndex = numbers.length - 12;
    }
    const ret = numbers.slice(lastActualMonthIndex)
        .map((val, index, arr) => {
            let className = undefined;
            if (!hideReforecast && (index == 1 || index == arr.length - 12)) {
                className = css.periodSplit;
            }
            return (
                <Cell key={index} className={className}>
                    {val !== null ? formatter.format(val) : ""}
                </Cell>
            );
        });
    return ret;
}

export function buildCellsCsv(reforecastStartMonthIndex: number, numbers: (number | null)[], formatter: Intl.NumberFormat): string[] {
    const ret = numbers.slice(reforecastStartMonthIndex - 1)
        .map((val) => val !== null ? formatter.format(val) : "");
    return ret;
}
