import { ApexOptions } from "apexcharts";
import { ReactElement } from "react";
import Chart from "react-apexcharts";
import { COLORS } from "../../../../constants/Colors";
import { MONTHS } from "../../../../constants/Months";
import { buildApexChartConfig } from "../../../../styles/apex-charts/ViziblyChartDefaultConfig";
import {singleItemTooltip, TVarianceTooltipItem, varianceTooltip} from "../../../../styles/apex-charts/ViziblyTooltips";
import { formatterDollarUSNoDecimal } from "../../../../utils/formatters";
import { TOperationalChartDataBundle } from "../../data/useOperationalData";

export interface IOperationalGraphProps {
    chartData: TOperationalChartDataBundle,
    dataType: 'dollar' | 'percent' | 'count',
}

export function OperationalGraph(props: IOperationalGraphProps): ReactElement {
    const values: number[] = [];
    props.chartData.chartSeriesData.map(x => (
        values.push(...(x.values.filter(y => typeof y === "number") as number[]))
    ));

    const yMin = Math.min(...values) * .9;
    const yMax = Math.max(...values) * 1.1;

    const options: ApexOptions = {
        chart: {
            type: 'line',
            stacked: true,
        },
        legend: {
            show: true,
            horizontalAlign: 'left',
        },
        xaxis: {
            categories: MONTHS,
        },
        stroke: {
            show: true,
            colors: props.chartData.chartSeriesData.length == 3 ? ['transparent', COLORS.LAVENDER_400] : undefined,
            width: props.chartData.chartSeriesData.length == 3 ? 2 : undefined,
            dashArray: props.chartData.chartSeriesData.length == 3 ? [0, 3] : undefined
        },
        yaxis: {
            min: yMin < 0 ? 0 : yMin,
            max: (yMax > 100 && props.dataType == 'percent') ? 100 : yMax,
            forceNiceScale: false,
            labels: {
                formatter: (val: number) => {
                    if (!val || val == 0) {
                        return '0';
                    }

                    switch (props.dataType) {
                        case 'count': {
                            return String(val.toFixed());
                        }
                        case 'dollar': {
                            return `${formatterDollarUSNoDecimal.format(val)}`;
                        }
                        default:
                            return `${val.toFixed(1)}%`;
                    }
                },
            },
        },
        tooltip: {
            custom: ({ dataPointIndex, w }) => {
                const tooltipTitle = w.globals.categoryLabels[dataPointIndex];
                const series: ApexAxisChartSeries = w.config.series;
                const itemOne: Partial<TVarianceTooltipItem> = {};
                const itemTwo: Partial<TVarianceTooltipItem> = {};

                if(series.length > 1) {
                    series.forEach((seriesItem) => {
                        const value = seriesItem?.data[dataPointIndex] as (number | undefined);
                        const name = seriesItem?.name as (string | undefined);
                        const color = seriesItem?.color as (string | undefined);

                        if (value != null) {
                            if (itemOne.value == undefined) {
                                itemOne.label = name;
                                itemOne.value = value;
                                itemOne.color = color;
                            } else {
                                itemTwo.label = name;
                                itemTwo.value = value;
                                itemTwo.color = color;
                            }
                        }
                    });

                    return varianceTooltip(
                            tooltipTitle,
                            itemOne as TVarianceTooltipItem,
                            itemTwo as TVarianceTooltipItem,
                            props.chartData.varianceCounts[dataPointIndex],
                            props.dataType
                    );
                } else {
                    const seriesItem = series[0];
                    itemOne.label = seriesItem?.name as (string | undefined);
                    itemOne.color = seriesItem?.color as (string | undefined);
                    itemOne.value = seriesItem?.data[dataPointIndex] as (number | undefined);
                    return singleItemTooltip(
                            tooltipTitle,
                            itemOne as TVarianceTooltipItem,
                            props.dataType
                    );
                }
            },
            shared: true,
        }
    };

    const mappedApexSeries: ApexAxisChartSeries = props.chartData.chartSeriesData.map(x => ({
        name: x.name,
        color: x.color,
        type: x.type,
        data: x.values,
    }));

    const chartConfig = buildApexChartConfig({ options, series: mappedApexSeries });

    return (
        <Chart
            options={chartConfig.options}
            series={chartConfig.series}
            height={360}
        />
    );
}