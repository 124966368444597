
import { useEffect, useState } from "react";
import {
    GetPropertyAccountDriverStatusQuery,
    GetPropertyExecutiveSummaryDataQuery,
    TopCardTagType,
    useGetPropertyAccountDriverStatusLazyQuery,
    useGetPropertyExecutiveSummaryDataLazyQuery, VersionType
} from "../../../__generated__/generated_types";
import {Property} from "../../../contexts/properties/PropertiesContext";
import {
    buildBudgetedAvgMarketRentStat, buildCommentsPreviewData, buildSummaryDataAverageMarketRent,
    buildSummaryDataAverageOccupancy,
    buildSummaryDataExpiringCounts,
    buildSummaryDataFinancialMetric,
    buildSummaryDataTradeOuts,
    CommentPreviewData,
    TBudgetedAvgMarketRentStat,
    TSummaryDataAverageOccupancy, TSummaryDataFinancialMetric,
} from "./logic";
import { buildGraphSeriesDataExpirations, buildGraphSeriesDataFinancialMetric, buildGraphSeriesDataMoveOutRatios, buildGraphSeriesDataMoveOuts, buildGraphSeriesDataOccupancy, buildGraphSeriesDataRenewalRatios, buildGraphSeriesDataRenewals, ExecutiveSummaryGraphData } from "./seriesGraphDataLogic";
import {
    buildCustomT12ReportTablesData,
    buildFinancialsTableData,
    buildReportTablesData,
    ReportTableData
} from "./reportTablesLogic";
import { buildChartOfAccountsFlatComponentsBottomLine, FinancialEntity, useChartOfAccounts } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { ExecutiveSummaryUserInfo } from "../PropertyExecutiveSummary";
import { RevenueSummaryUnitType } from "../../workflows/operational/summary/RevenueSummaryTable";
import { IFinancialsCOATableRow } from "../components/helpers";
import "native-injects";
import { raw } from "@storybook/react";
import { filter } from "lodash";


export enum SERIES_DATA_OPTION {
    EXPIRATIONS,
    OCCUPANCY,
    RENEWALS,
    RENEWAL_RATIOS,
    RENEWAL_TRADEOUTS,
    MOVE_OUTS,
    MOVE_OUTS_RATIOS,
    NEW_LEASE_TRADEOUTS,
    NOI,
    RENTAL_INCOME,
    TOTAL_EXPENSE,
    BUDGETED_MARKET_RENT,
}


export interface EmployeeTotals {
    employeeId: string;
    positionName: string;
    basePayTotal: number;
    raiseTotal: number;
}

interface FinancialMetricVarianceData {
    delta: number;
    deltaPercent: number;
}

export type PropertyExecutiveSummaryData = {
    rawData: GetPropertyExecutiveSummaryDataQuery | undefined,
    rawDataLoading: boolean,
    property: Property,
    user: ExecutiveSummaryUserInfo,

    isUsingCustomT12: boolean,
    customT12Month: number | null,
    customT12Year: number | null,
    customT12Label: string | null,

    unitTypes: RevenueSummaryUnitType[],

    financialMetricsSetupRequired: boolean,

    noi: FinancialMetricVarianceData | null,
    customT12Noi: FinancialMetricVarianceData | null,

    rentalIncome: FinancialMetricVarianceData | null,
    customT12RentalIncome: FinancialMetricVarianceData | null,

    totalExpense: FinancialMetricVarianceData | null,
    customT12TotalExpense: FinancialMetricVarianceData | null,

    avgMarketRentReforecast: number | null,
    avgMarketRentBudget: number | null,
    avgMarketRentDeltaPercent: number | null,

    occupancyStat: TSummaryDataAverageOccupancy,

    budgetedAvgMarketRent: TBudgetedAvgMarketRentStat,
    marketRentGrowthPercent: number | null,

    budgetExpirations: number | null,
    budgetExpiringMoveOuts: number | null,
    budgetExpiringRenewals: number | null,
    budgetExpiringMoveOutRatio: number | null,
    budgetExpiringRenewalRatio: number | null,

    newLeaseTradeOut: number | null,
    newLeaseTradeOutPercent: number | null,
    renewalTradeOut: number | null,
    renewalTradeOutPercent: number | null,

    graphSeriesData: Map<SERIES_DATA_OPTION, ExecutiveSummaryGraphData>,
    customT12GraphSeriesData: Map<SERIES_DATA_OPTION, ExecutiveSummaryGraphData>,
    reportTables: ReportTableData[],
    customT12ReportTables: ReportTableData[],
    financialsTable: IFinancialsCOATableRow[] | null,
    customT12FinancialsTable: IFinancialsCOATableRow[] | null,
    commentsMap: Map<string, CommentPreviewData>,

    employeeTotals: EmployeeTotals[]
}

export function usePropertyExecutiveSummaryData(property: Property | undefined, user: ExecutiveSummaryUserInfo): PropertyExecutiveSummaryData | undefined {
    const [loadData, { data, loading }] = useGetPropertyExecutiveSummaryDataLazyQuery({ fetchPolicy: "no-cache" });
    const [loadAccountDriverStatus, { data: accountDriverStatusData, loading: accountDriverStatusLoading }] = useGetPropertyAccountDriverStatusLazyQuery({ fetchPolicy: "no-cache" });
    const coa = useChartOfAccounts();
    const [executiveSummaryData, setExecutiveSummaryData] = useState<PropertyExecutiveSummaryData>();
    useEffect(
        () => {
            if (!property) {
                return;
            }
            loadData({
                variables: {
                    propertyId: property.id,
                    budgetYear: property.budgetYear,
                    topCardTagTypes: []
                }
            });
            loadAccountDriverStatus({
                variables: {
                    propertyId: property.id,
                    budgetYear: property.budgetYear
                }
            });
        },
        [property]
    );

    useEffect(
        () => {
            if (property && data && !loading && user && coa.isReady && coa.chartOfAccountsFlat && coa.chartOfAccounts && accountDriverStatusData && !accountDriverStatusLoading) {
                const executiveSummaryData = buildSummaryData(property, user, data, loading, coa.chartOfAccounts, accountDriverStatusData.queryPropertyAccountDriverStatus);
                setExecutiveSummaryData(executiveSummaryData);
            }
        },
        [data, loading, user, coa.isReady, coa.chartOfAccountsFlat, accountDriverStatusData, accountDriverStatusLoading]
    );
    return executiveSummaryData;
}

function buildUnitTypes(rawData: GetPropertyExecutiveSummaryDataQuery): RevenueSummaryUnitType[] {
    let ret:RevenueSummaryUnitType[] = [];
    if (rawData.originalRevenueModel) {
        ret = [{ unitTypeId: "all", unitTypeName: "All Unit Types" },
            ...Object.entries(rawData
                .originalRevenueModel
                .unitTypeUnitCount
                .toIdMap("unitTypeId", "unitTypeName")
            )
                .map(([unitTypeId, unitTypeName]) => ({ unitTypeId, unitTypeName }))
                .sortBy("unitTypeName")
        ];
    }

    return ret;
}

function filterT12ChartData(data: ExecutiveSummaryGraphData): ExecutiveSummaryGraphData {
    function filterTableData(
        td: {colHeaders: string[], data: any[][]} | undefined,
        itemLabel: string
    ): {colHeaders: string[], data: any[][]} | undefined {
        if(!td) {
            return td;
        }

        let colIndex = 1;
        for(; colIndex < td.colHeaders.length; colIndex++) {
            if(td.colHeaders[colIndex] === itemLabel) {
                break;
            }
        }
        if(colIndex >= td.colHeaders.length) {
            return td;
        }

        return {
            colHeaders: [td.colHeaders[0] as string, td.colHeaders[colIndex] as string],
            data: td.data.map(d => {
                return [d[0], d[colIndex]];
            })
        };
    }

    const copy: ExecutiveSummaryGraphData = {
        summary: data.summary,
        dataType: data.dataType,
        varianceCounts: [],
        chartSeriesData: data.chartSeriesData
            .filter(sd => sd.versionType === VersionType.Budget)
            .map(sd => ({...sd, type: "bar"})),
        tableData: filterTableData(data.tableData, data.summary.comparisonItemTwo.label)
    };
    return copy;
}

function customT12LabelText(year: number, month: number): string {
    const yearPart = year % 100;
    switch(month) {
        case 0:
            return `Jan '${yearPart}`;
        case 1:
            return `Feb '${yearPart}`;
        case 2:
            return `Mar '${yearPart}`;
        case 3:
            return `Apr '${yearPart}`;
        case 4:
            return `May '${yearPart}`;
        case 5:
            return `Jun '${yearPart}`;
        case 6:
            return `Jul '${yearPart}`;
        case 7:
            return `Aug '${yearPart}`;
        case 8:
            return `Sep '${yearPart}`;
        case 9:
            return `Oct '${yearPart}`;
        case 10:
            return `Nov '${yearPart}`;
        case 11:
            return `Dec '${yearPart}`;
        default:
            return `'${yearPart}`;
    }
}

function buildSummaryData(property: Property,
                          user: ExecutiveSummaryUserInfo,
                          rawData: GetPropertyExecutiveSummaryDataQuery,
                          rawDataLoading: boolean,
                          chartOfAccounts: FinancialEntity[],
                          accountDriverStatusData: GetPropertyAccountDriverStatusQuery["queryPropertyAccountDriverStatus"]): PropertyExecutiveSummaryData {
    const isUsingCustomT12 = !!rawData.clientReportT12FinancialValues;
    const chartOfAccountsFlat = buildChartOfAccountsFlatComponentsBottomLine(chartOfAccounts);
    const chartOfAccountsFlatCategoryBottom = buildChartOfAccountsFlatComponentsBottomLine(chartOfAccounts, true);

    const avgMarketRentStat = buildSummaryDataAverageMarketRent(rawData);
    const occupancyStat = buildSummaryDataAverageOccupancy(property, rawData);

    const noiStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.NetOperatingIncome, false);
    const customT12NoiStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.NetOperatingIncome, true);
    const rentalIncomeStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.RentalIncome, false);
    const customT12RentalIncomeStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.RentalIncome, true);
    const totalExpenseStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.TotalExpenses, false);
    const customT12TotalExpenseStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.TotalExpenses, true);
    const budgetedMarketRentStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.MarketRent, false);
    const budgetAvgMarketRentStat =  buildBudgetedAvgMarketRentStat(budgetedMarketRentStat, occupancyStat);

    const expiringCountsStat = buildSummaryDataExpiringCounts(property, rawData);
    const tradeOutsStat = buildSummaryDataTradeOuts(rawData);
    const reportTables = buildReportTablesData(property, rawData, chartOfAccountsFlatCategoryBottom, occupancyStat.budgetUnitCountMonthly.average());
    const customT12ReportTables = buildCustomT12ReportTablesData(property, rawData, chartOfAccountsFlatCategoryBottom, occupancyStat.budgetUnitCountMonthly.average());
    const unitTypes = buildUnitTypes(rawData);
    const financialsTable = isUsingCustomT12 ? null : buildFinancialsTableData(property, rawData, chartOfAccountsFlat, accountDriverStatusData, false);
    const customT12FinancialsTable = isUsingCustomT12 ? buildFinancialsTableData(property, rawData, chartOfAccountsFlat, accountDriverStatusData, true) : null;
    const commentPreviews = buildCommentsPreviewData(rawData);

    function financialMetricToVariance(stat: TSummaryDataFinancialMetric | null): FinancialMetricVarianceData | null {
        if(stat === null) {
            return null;
        }

        return {
            delta: stat.varianceAmount,
            deltaPercent: stat.variancePercent,
        };
    }

    const executiveSummaryData: PropertyExecutiveSummaryData = {
        rawData: rawData,
        rawDataLoading: rawDataLoading,
        property: property,
        isUsingCustomT12: isUsingCustomT12,
        customT12Month: rawData.clientReportT12FinancialValues?.t12Month ?? null,
        customT12Year: rawData.clientReportT12FinancialValues?.t12Year ?? null,
        customT12Label: rawData.clientReportT12FinancialValues ?
            customT12LabelText(rawData.clientReportT12FinancialValues.t12Year, rawData.clientReportT12FinancialValues.t12Month) : null,
        user: user,
        unitTypes,

        financialMetricsSetupRequired: false,

        noi: financialMetricToVariance(noiStat),
        customT12Noi: financialMetricToVariance(customT12NoiStat),

        rentalIncome: financialMetricToVariance(rentalIncomeStat),
        customT12RentalIncome: financialMetricToVariance(customT12RentalIncomeStat),

        totalExpense: financialMetricToVariance(totalExpenseStat),
        customT12TotalExpense: financialMetricToVariance(customT12TotalExpenseStat),

        avgMarketRentReforecast: avgMarketRentStat.reforecastValue,
        avgMarketRentBudget: avgMarketRentStat.budgetValue,
        avgMarketRentDeltaPercent: avgMarketRentStat.deltaPercent,
        occupancyStat: occupancyStat,

        budgetedAvgMarketRent: budgetAvgMarketRentStat,
        marketRentGrowthPercent: budgetedMarketRentStat ? budgetedMarketRentStat.variancePercent : null,

        budgetExpirations: expiringCountsStat.budgetExpirations,
        budgetExpiringMoveOuts: expiringCountsStat.budgetExpiringMoveOuts,
        budgetExpiringRenewals: expiringCountsStat.budgetExpiringRenewals,
        budgetExpiringMoveOutRatio: expiringCountsStat.budgetExpiringMoveOutRatio,
        budgetExpiringRenewalRatio: expiringCountsStat.budgetExpiringRenewalRatio,

        newLeaseTradeOut: tradeOutsStat.newLeaseTradeOut,
        newLeaseTradeOutPercent: tradeOutsStat.newLeaseTradeOutPercent,
        renewalTradeOut: tradeOutsStat.renewalTradeOut,
        renewalTradeOutPercent: tradeOutsStat.renewalTradeOutPercent,

        graphSeriesData: new Map(),
        customT12GraphSeriesData: new Map(),

        reportTables: reportTables,
        customT12ReportTables: customT12ReportTables,
        financialsTable: financialsTable,
        customT12FinancialsTable: customT12FinancialsTable,
        commentsMap: commentPreviews,

        employeeTotals: rawData.employeeTotals
    };

    const occupancyData = buildGraphSeriesDataOccupancy(occupancyStat, property);
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.OCCUPANCY, occupancyData);
    executiveSummaryData.customT12GraphSeriesData.set(SERIES_DATA_OPTION.OCCUPANCY, filterT12ChartData(occupancyData));

    const expirationsData = buildGraphSeriesDataExpirations(expiringCountsStat, property);
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.EXPIRATIONS, expirationsData);
    executiveSummaryData.customT12GraphSeriesData.set(SERIES_DATA_OPTION.EXPIRATIONS, filterT12ChartData(expirationsData));

    const renewalsData = buildGraphSeriesDataRenewals(expiringCountsStat, property);
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.RENEWALS, renewalsData);
    executiveSummaryData.customT12GraphSeriesData.set(SERIES_DATA_OPTION.RENEWALS, filterT12ChartData(renewalsData));

    const renewalRatiosData = buildGraphSeriesDataRenewalRatios(expiringCountsStat, property);
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.RENEWAL_RATIOS, renewalRatiosData);
    executiveSummaryData.customT12GraphSeriesData.set(SERIES_DATA_OPTION.RENEWAL_RATIOS, filterT12ChartData(renewalRatiosData));

    const moveOutsData = buildGraphSeriesDataMoveOuts(expiringCountsStat, property);
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.MOVE_OUTS, moveOutsData);
    executiveSummaryData.customT12GraphSeriesData.set(SERIES_DATA_OPTION.MOVE_OUTS, filterT12ChartData(moveOutsData));

    const moveOutRatiosData = buildGraphSeriesDataMoveOutRatios(expiringCountsStat, property);
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.MOVE_OUTS_RATIOS, moveOutRatiosData);
    executiveSummaryData.customT12GraphSeriesData.set(SERIES_DATA_OPTION.MOVE_OUTS_RATIOS, filterT12ChartData(moveOutRatiosData));

    if(noiStat){
        const noiData = buildGraphSeriesDataFinancialMetric(noiStat, property);
        executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.NOI, noiData);
        executiveSummaryData.customT12GraphSeriesData.set(SERIES_DATA_OPTION.NOI, filterT12ChartData(noiData));
    }

    if(rentalIncomeStat){
        const rentalIncomeData = buildGraphSeriesDataFinancialMetric(rentalIncomeStat, property);
        executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.RENTAL_INCOME, rentalIncomeData);
        executiveSummaryData.customT12GraphSeriesData.set(SERIES_DATA_OPTION.RENTAL_INCOME, filterT12ChartData(rentalIncomeData));
    }

    if(totalExpenseStat){
        const totalExpenseData = buildGraphSeriesDataFinancialMetric(totalExpenseStat, property);
        executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.TOTAL_EXPENSE, totalExpenseData);
        executiveSummaryData.customT12GraphSeriesData.set(SERIES_DATA_OPTION.TOTAL_EXPENSE, filterT12ChartData(totalExpenseData));
    }

    if(budgetedMarketRentStat){
        const budgetedMarketRentData = buildGraphSeriesDataFinancialMetric(budgetedMarketRentStat, property);
        executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.BUDGETED_MARKET_RENT, budgetedMarketRentData);
        executiveSummaryData.customT12GraphSeriesData.set(SERIES_DATA_OPTION.BUDGETED_MARKET_RENT, filterT12ChartData(budgetedMarketRentData));
    }

    return executiveSummaryData;
}
