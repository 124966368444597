import { formatValueForDataType, getFormattedVariances } from "../../pages/planning-dashboard/data/logic/operationalData";
import * as css from "./styles.module.scss";

export type TVarianceTooltipItem = {
    label: string,
    value: number,
    color: string,
}

export function singleItemTooltip(
    title: string,
    item: TVarianceTooltipItem,
    dataType: 'dollar' | 'percent' | 'count'
): string | null {
    const valOneStringValue = formatValueForDataType(item.value, dataType);
    return `
        <div class="${css.viziblyApexChartTooltip}">
            <h5 class="${css.tooltipHeader}">${title}</h5>
            <div class="${css.tooltipValue}">
                <div class="${css.tooltipValueColorIndicator}" style="background-color: ${item.color}"></div>
                <div class="${css.tooltipValueLabel}">${item.label}</div>
                <div class="${css.tooltipValueAmount}">${valOneStringValue}</div>
            </div>
        </div>
    `;
}

// Returns HTML as a string for a variance tooltip
export function varianceTooltip(
    title: string,
    itemOne: TVarianceTooltipItem,
    itemTwo: TVarianceTooltipItem,
    _varianceCount: number | null | undefined,
    dataType: 'dollar' | 'percent' | 'count',
): string | null {
    const {
        valOneStringValue,
        valTwoStringValue,
        variancePercent,
        varianceCount,
    } = getFormattedVariances(itemOne.value, itemTwo.value, _varianceCount, dataType);

    let tooltipHTML = `
        <div class="${css.viziblyApexChartTooltip}">
            <h5 class="${css.tooltipHeader}">${title}</h5>
            <div class="${css.tooltipValue}">
                <div class="${css.tooltipValueColorIndicator}" style="background-color: ${itemOne.color}"></div>
                <div class="${css.tooltipValueLabel}">${itemOne.label}</div>
                <div class="${css.tooltipValueAmount}">${valOneStringValue}</div>
            </div>
            <div class="${css.tooltipValue}">
                <div class="${css.tooltipValueColorIndicator}" style="background-color: ${itemTwo.color}"></div>
                <div class="${css.tooltipValueLabel}">${itemTwo.label}</div>
                <div class="${css.tooltipValueAmount}">${valTwoStringValue}</div>
            </div>
            <hr class="${css.tooltipDivider}">
            <div class="${css.tooltipVariance}">
                <div class="${css.tooltipVarianceLabel}">% Change</div>
                <div class="${css.tooltipVarianceAmount}">${variancePercent}</div>
            </div>
    `;

    if (dataType == 'dollar') {
        tooltipHTML += `
            <div class="${css.tooltipVariance}">
                <div class="${css.tooltipVarianceLabel}">$ Change</div>
                <div class="${css.tooltipVarianceAmount}">${formatValueForDataType(varianceCount, 'dollar')}</div>
            </div>
        `;
    } else {
        tooltipHTML += `
            <div class="${css.tooltipVariance}">
                <div class="${css.tooltipVarianceLabel}"># Change</div>
                <div class="${css.tooltipVarianceAmount}">${formatValueForDataType(varianceCount, 'count')}</div>
            </div>
        `;
    }

    tooltipHTML += '</div>';

    return tooltipHTML;
}