export interface IAnimateBackgroundLayer {
    imageHeightFactor: number,
    imageXPos: React.MutableRefObject<number>,
    imageYPos: number,
    imageRef: React.MutableRefObject<HTMLImageElement | null>,
    canvasContext: CanvasRenderingContext2D,
    canvasWidth: number,
    canvasHeight: number,
    scrollSpeed: number,
}

export function animateBackgroundLayer(props: IAnimateBackgroundLayer) {
    const {
        imageHeightFactor,
        imageXPos,
        imageYPos,
        imageRef,
        canvasContext,
        canvasWidth,
        canvasHeight,
        scrollSpeed,
    } = props;

    if (!imageRef.current) {
        return;
    }

    imageXPos.current -= scrollSpeed;

    if (imageXPos.current <= -canvasWidth) {
        imageXPos.current = 0;
    }

    canvasContext.drawImage(
        imageRef.current,
        imageXPos.current,
        (canvasHeight * imageYPos),
        canvasWidth,
        (canvasHeight * imageHeightFactor)
    );

    canvasContext.drawImage(
        imageRef.current,
        imageXPos.current + canvasWidth,
        (canvasHeight * imageYPos),
        canvasWidth,
        (canvasHeight * imageHeightFactor)
    );
}
