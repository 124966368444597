import { useState } from "react";
import * as Comlink from 'comlink';
import { PropertyExecutiveSummaryData, SERIES_DATA_OPTION } from "../logic/usePropertyExecutiveSummaryData";
import { ExecutiveSummaryGraphData } from "../logic/seriesGraphDataLogic";
import { Button } from "@zendeskgarden/react-buttons";
import { WorkerType } from './pdfWorker';
import { Inline } from "@zendeskgarden/react-loaders";
import { FaFileDownload } from "react-icons/fa";
import { useAsyncCallback } from 'react-async-hook';
import { saveAs } from 'file-saver';
import { buildExecSummaryMetricData } from "./buildAnalysisPageData";
import { exportCharts } from "./exportToPdf";
import { financialTableDataToRows } from "./buildFinancialTableData";
import { revenueTableDataToRows } from "./buildRevenueTableData";
import {CUSTOM_T12_TEXT, loadClientBrandAsset, OPERATIONAL_MENU_OPTIONS} from "../constants";
import { buildExecutiveSummaryStatementNOI, buildExecutiveSummaryStatementRentalIncome, buildExecutiveSummaryStatementRents, buildExecutiveSummaryStatementTotalExpense, buildRentsStatementMarketRent, buildRentsStatementMoveOutRatio, buildRentsStatementOccupancy, buildRentsStatementRenewalRatio, buildRentsStatementResidentsTurnover, buildRentsStatementSummary, buildRentsStatementTradeouts, formatAsPDF } from "../logic/summaryStringBuilders";
import { IExecutiveSummaryPDFExportWorkerProps } from './pdfExportHelpers';
import { payrollTableDataToRows } from "./buildPayrollTableData";
// eslint-disable-next-line
import Worker from "worker-loader!./pdfWorker";

export const DownloadButton = (props: { data: PropertyExecutiveSummaryData | undefined, rawChartData: Map<SERIES_DATA_OPTION, ExecutiveSummaryGraphData> }) => {
    const { data, rawChartData } = props;
    const [isLoading, setIsLoading] = useState(false);
    const exportAction = useExportCallback({
        data,
        rawChartData,
    });

    return <Button
                onClick={() => {
                    setIsLoading(true);
                    exportAction.execute().finally(() => setIsLoading(false));
                    }} disabled={data === undefined || isLoading}

    >
        {
            isLoading ?
                <>Generating PDF <Inline size={22} style={{ marginLeft: ".5rem" }} /></>
                : <>Download PDF
                <Button.EndIcon><FaFileDownload /></Button.EndIcon>
                </>
        }
    </Button>;
};

export const useExportCallback = (props: { data: PropertyExecutiveSummaryData | undefined, rawChartData: Map<SERIES_DATA_OPTION, ExecutiveSummaryGraphData> }) => useAsyncCallback(async () => {
    const { data, rawChartData } = props;

    if (!data) {
        return;
    }

    const worker = new Worker();
    const pdfWorker = Comlink.wrap<WorkerType>(worker);
    // pdfWorker.onProgress(Comlink.proxy((info: string) => console.log(info)));

    const fileName = `${data.property.name} ${data.property.budgetYear} Executive Summary.pdf`;

    const title_by_series_option = OPERATIONAL_MENU_OPTIONS.toIdMap("value", "label");
    const financialOverviewData = financialTableDataToRows(
            data.property,
            (data.isUsingCustomT12 ? data.customT12FinancialsTable : data.financialsTable) ?? [],
            data.isUsingCustomT12,
            `${CUSTOM_T12_TEXT} (${data.customT12Label})`
    );
    const revenueSummaryData = revenueTableDataToRows(data.property, data?.rawData?.originalRevenueModel);
    const payrollTableData = payrollTableDataToRows(data.employeeTotals);
    const charts = await exportCharts(data.isUsingCustomT12 ? data.customT12GraphSeriesData : data.graphSeriesData);
    const executiveSummaryStatementNOI = buildExecutiveSummaryStatementNOI(data);
    const executiveSummaryStatementRents = buildExecutiveSummaryStatementRents(data);
    const executiveSummaryStatementRentalIncome = buildExecutiveSummaryStatementRentalIncome(data);
    const executiveSummaryStatementTotalExpense = buildExecutiveSummaryStatementTotalExpense(data);
    const fullExecutiveSummaryStatementNOI = `${executiveSummaryStatementNOI} ${executiveSummaryStatementRents} ${executiveSummaryStatementRentalIncome} ${executiveSummaryStatementTotalExpense}`;
    const rentsStatementSummary = buildRentsStatementSummary(data);
    const execSummaryMetricData = buildExecSummaryMetricData(data);
    const clientBranding = loadClientBrandAsset(data.user.clientId);

    const dataProps: IExecutiveSummaryPDFExportWorkerProps = {
        data,
        customT12Text: CUSTOM_T12_TEXT,
        rawChartData,
        financialOverviewData,
        revenueSummaryData,
        payrollTableData,
        charts,
        title_by_series_option,
        fullExecutiveSummaryStatementNOI,
        executiveSummaryStatementNOI,
        rentsStatementSummary,
        execSummaryMetricData: JSON.stringify(execSummaryMetricData),
        clientBranding,
    };
    try {
        const blob = await pdfWorker.generateSingle(dataProps);
        saveAs(blob, fileName);
    } catch (error) {
        console.log("Error generating PDF");
        console.trace(error);
    }
    finally {
        worker.terminate();
    }
});
