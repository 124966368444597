import { ApexOptions } from "apexcharts";
import { ExecutiveSummaryGraphData } from "../logic/seriesGraphDataLogic";
import { SERIES_DATA_OPTION } from "../logic/usePropertyExecutiveSummaryData";
import { MONTHS } from "../../../constants/Months";
import { COLORS } from "../../../constants/Colors";
import { formatterDollarUSNoDecimal } from "../../../utils/formatters";

export async function exportCharts(data: Map<SERIES_DATA_OPTION, ExecutiveSummaryGraphData>): Promise<Map<SERIES_DATA_OPTION, string>> {
    const ret:Map<SERIES_DATA_OPTION, string> = new Map();
    for (const what of Array.from(data.keys())) {
        const uri = await exportChart(what, data);
        if (uri) {
            ret.set(what, uri);
        }
    }

    return ret;
}
async function exportChart(what: SERIES_DATA_OPTION, graphSeriesData: Map<SERIES_DATA_OPTION, ExecutiveSummaryGraphData>): Promise<string | undefined> {
    const values: number[] = [];
    const chartData = graphSeriesData.get(what);
    if (!chartData) {
        return ;
    }
    chartData.chartSeriesData.map(x => (
        values.push(...(x.values.filter(y => typeof y === "number") as number[]))
    ));
    const yMin = Math.min(...values) * .9;
    const yMax = Math.max(...values) * 1.1;

    const mappedApexSeries: ApexAxisChartSeries = chartData.chartSeriesData.map(x => ({
        name: x.name,
        color: x.color,
        type: x.type,
        data: x.values,
    }));
    const options: ApexOptions = {
        colors: [COLORS.LAVENDER_300, COLORS.PRIMARY_200, COLORS.PRIMARY_200],
        series: mappedApexSeries,
        chart: {
            type: 'line',
            stacked: true,
            animations: {enabled: false},
            height: 260,
        },
        legend: {
            show: true,
            horizontalAlign: 'left',
        },
        xaxis: {
            categories: MONTHS,
        },
        stroke: {
            show: true,
            colors: chartData.chartSeriesData.length == 3 ? ['transparent', COLORS.LAVENDER_400] : undefined,
            width: chartData.chartSeriesData.length == 3 ? 2 : 0,
            dashArray: chartData.chartSeriesData.length == 3 ? [0, 3] : undefined
        },
        yaxis: {
            min: yMin < 0 ? 0 : yMin,
            max: (yMax > 100 && chartData.dataType == 'percent') ? 100 : yMax,
            forceNiceScale: false,
            labels: {
                formatter: (val: number) => {
                    if (!val || val == 0) {
                        return '0';
                    }

                    switch (chartData.dataType) {
                        case 'count': {
                            return String(val.toFixed());
                        }
                        case 'dollar': {
                            return `${formatterDollarUSNoDecimal.format(val)}`;
                        }
                        default:
                            return `${val.toFixed(1)}%`;
                    }
                },
            },
        }
    };

    const el = document.querySelector("#graph_tmp_anchor");
    if (!el) {
        return undefined;
    }
    for (const child of Array.from(el.children)) {
        el.removeChild(child);
    }
    const chartInstance = new ApexCharts(el, options);
    const imgURI = await new Promise<string | undefined>((resolve) => chartInstance.render().then(() => {
            chartInstance.exports.dataURI({scale: 1.5}).then((arg) => {
                if (Object.prototype.hasOwnProperty.call(arg, "imgURI")) {
                    resolve((arg as {imgURI: string}).imgURI);
                }
                else {
                    resolve(undefined);
                }
            })
            .catch(() => resolve(undefined));
        })
        .catch(() => {resolve(undefined);})
    );
    return imgURI;
}
