import { IDownloadableExportsTableRow } from "./DownloadableExportsTable";

export const getSortedReports = (reports: IDownloadableExportsTableRow[]): IDownloadableExportsTableRow[] =>
    reports.sort((a, b) => {
        return new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime();
});

export const formatDate = (date: Date): string => {
    const tempDate = new Date(date);
    const dateConversionOptions: Intl.DateTimeFormatOptions = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };

    return tempDate.toLocaleDateString('en-US', dateConversionOptions);
};

export const pageDescription = 'Exports are pulled automatically. The last 30 days of exports are available to download.';
